<template>
  <div class="shop" v-if="!$store.state.loading">
    <div class="zoom-image-container" data-aos="fade-up" data-aos-duration="1000" id="sim_racing" style="margin-top: 70px">
      <img :src="images[1]" alt="CarboBrake Sim Racing" style="width: 40%; transform: scale(1); margin: 100px auto;" class="desktop"/>
      <img :src="images[0]" alt="CarboBrake Sim Racing" style="width: 75%; transform: scale(1); margin: 0 auto;" class="mobile"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/shop_carbo_mobile.png'),
        require('../assets/shop_carbo.png'),
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss">

  @media (max-width: 960px) {
    .shop .desktop{
      display: none;
    }
  }
  @media (min-width: 961px) {
    .shop .mobile{
      display: none;
    }
  }
  @media (max-width: 768px) {
    #app #main-container .shop .zoom-image-container {
      height: 400px !important;
    }
  }

</style>